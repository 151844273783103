import React, { useState } from "react";
import { FiGrid, FiHome, FiPhoneOutgoing, FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";

const Header = () => {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    return (
        <>
            <div className={navbar ? "tm_topbar animate" : "tm_topbar"}>
                <div className="in">
                    <div className="topbar_inner">
                        <div className="logo">
                            <Link to="/">
                                <img src="img/logo/logo.png" alt="brand" />
                            </Link>
                        </div>
                        <div className="menu">
                            <Scrollspy className="anchor_nav" items={["home", "service", "portfolio", "contact"]} currentClassName="current" offset={-88}>
                                <li className="current">
                                    <a href="#home">
                                        <span className="first">Strona główna</span>
                                        <span className="second">Strona główna</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#service">
                                        <span className="first">Oferta</span>
                                        <span className="second">Oferta</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#portfolio">
                                        <span className="first">Portfolio</span>
                                        <span className="second">Portfolio</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#contact">
                                        <span className="first">Kontakt</span>
                                        <span className="second">Kontakt</span>
                                    </a>
                                </li>
                            </Scrollspy>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-menu-wrapper">
                <Scrollspy className="mobile_menu-icon" items={["home", "service", "portfolio", "contact"]} currentClassName="current" offset={-65}>
                    <li>
                        <a href="#home">
                            <FiHome />
                            <span>Strona główna</span>
                        </a>
                    </li>
                    <li>
                        <a href="#service">
                            <FiSettings />
                            <span>Oferta</span>
                        </a>
                    </li>
                    <li>
                        <a href="#portfolio">
                            <FiGrid />
                            <span>Portfolio</span>
                        </a>
                    </li>
                    <li>
                        <a href="#contact">
                            <FiPhoneOutgoing />
                            <span>Kontakt</span>
                        </a>
                    </li>
                </Scrollspy>
            </div>
        </>
    );
};

export default Header;
