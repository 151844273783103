import React from "react";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import {BsTiktok} from 'react-icons/bs'

const SocialShare = [
    {
        iconName: <FiFacebook />,
        link: "https://www.facebook.com/wedding.fairy1",
    },
    {
        iconName: <FiInstagram />,
        link: "https://www.instagram.com/_wedding_fairy/",
    },
    {
        iconName: <BsTiktok />,
        link: "https://www.tiktok.com/@weddingfairy",
    },
];

const Social = () => {
    return (
        <ul>
            {SocialShare.map((val, i) => (
                <li key={i}>
                    <a href={val.link} target="_blank" rel="noreferrer">
                        <span>{val.iconName}</span>
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default Social;
