import React from "react";
import Contact from "../components/Contact";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Portfolio from "../components/portfolio/PortfolioAnimation";
import Service from "../components/service/ServiceAnimation";
import Slider from "../components/slider/SliderAnimation";

const HomeLightAnimation = () => {
    document.body.classList.add("light");
    return (
        <div className="home-light">
            <Header />
            {/* End Header */}

            <Slider />
            {/* End Slider */}

            {/* <div className="tm_about" id="about">
        <div className="container">
          <div className="tm_title_holder">
            <span>About Me</span>
            <h2>About Me</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
              egestas. Id fermentum nullam ipsum massa.
            </p>
          </div>
          <About />
        </div>
      </div> */}
            {/* /ABOUT */}

            <Service />

            {/* PORTFOLIO */}
            <div className="tm_portfolio" id="portfolio">
                <div className="container">
                    <div className="tm_title_holder">
                        <span>Portfolio</span>
                        <h2>Przykładowe realizacje</h2>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, egestas. Id fermentum nullam ipsum massa.</p> */}
                    </div>
                    {/* End tm_title */}
                    <Portfolio />
                </div>
            </div>
            {/* /PORTFOLIO */}

            {/* TESTIMONIALS */}
            {/* <div className="tm_testimonials">
        <div className="container">
          <div className="tm_title_holder">
            <span>Testimonials</span>
            <h2>What's Clients Say.</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
              egestas. Id fermentum nullam ipsum massa.
            </p>
          </div>
          <div
            className="testimonials_list"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <Testimonial />
          </div>
        </div>
      </div> */}
            {/* /TESTIMONIALS */}

            {/* NEWS */}
            {/* <div className="tm_news" id="news">
        <div className="container">
          <div className="tm_title_holder">
            <span>Blogs</span>
            <h2>Recent News</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
              egestas. Id fermentum nullam ipsum massa.
            </p>
          </div>
          <Blog />
        </div>
      </div> */}
            {/* /NEWS */}

            <Contact />

            {/* COPYRIGHT */}
            <div className="tm_copyright">
                <div className="container">
                    <Footer />
                </div>
            </div>
            {/* /COPYRIGHT */}
        </div>
    );
};

export default HomeLightAnimation;
