import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Routes from "./router/Routes";

const App = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <div className="tm_all_wrap">
            <ScrollToTop />
            <Routes />
        </div>
    );
};

export default App;
