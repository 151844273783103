import React, { useState } from "react";

const Address = () => {
    const [isNumberVisible, setIsNumberVisible] = useState(false);

    return (
        <ul>
            <li>
                <img className="svg" src="img/svg/phone.svg" alt="" />
                {!isNumberVisible ? (
                    <span style={{ cursor: "pointer" }} onClick={() => setIsNumberVisible(true)}>
                        Pokaż numer
                    </span>
                ) : (
                    <a href="tel:+48500709206">+48 500 709 206</a>
                )}
            </li>
            {/* End li */}

            <li>
                <img className="svg" src="img/svg/mail.svg" alt="" />
                <span>
                    <a href="mailto:ib-themes21@gmail.com">aga@wedding-fairy.pl</a>
                </span>
            </li>
            {/* End li */}

            <li>
                <img className="svg" src="img/svg/map.svg" alt="" />
                <span>
                    Gdańsk, Trójmiasto, Pomorskie
                    <br /> Polska
                </span>
            </li>
        </ul>
    );
};

export default Address;
