import React from "react";
import Address from "./Address";
import { useForm } from "react-hook-form";

const Contact = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data: any, e: any) => {
        const json = JSON.stringify({ ...data, access_key: "64fd6bce-221e-43bf-8acb-b486a0ced601" });
        fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: json,
        })
            .then(async (response) => {
                let json = await response.json();
                if (response.status === 200) {
                    console.log("sent");
                } else {
                    console.log(response);
                }
                JSON.stringify(json);
                e.target.reset();
                alert("Wysłano zapytanie!");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="tm_contact" id="contact">
            <div className="container">
                <div className="contact_inner">
                    <div className="left" data-aos="fade-right" data-aos-duration="1200">
                        <div className="tm_title_holder">
                            <span>Kontakt</span>
                            <h2>Porozmawiajmy o waszym ślubie</h2>
                        </div>
                        <div className="short_list">
                            <Address />
                        </div>
                    </div>

                    <div className="right" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="150">
                        <div className="title">
                            <p>
                                Umówmy się na
                                <br />
                                <span>darmowe pierwsze spotkanie</span>
                            </p>
                        </div>
                        <div className="fields">
                            <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="first_row">
                                    <input
                                        {...register("name", {
                                            required: true,
                                        })}
                                        type="text"
                                        placeholder="Imię *"
                                    />
                                    {errors.name && errors.name.type === "required" && <span className="invalid-feedback">Imię jest wymagane</span>}
                                </div>

                                <div className="second">
                                    <div className="left">
                                        <input
                                            {...register("email", {
                                                required: "Email jest wymagany",
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "Niepoprawny format",
                                                },
                                            })}
                                            type="email"
                                            placeholder="Email *"
                                        />
                                        {errors.email && <span className="invalid-feedback">{errors.email.message?.toString()}</span>}
                                    </div>
                                    <div className="right">
                                        <input
                                            {...register("subject", {
                                                required: true,
                                            })}
                                            placeholder="Temat *"
                                        />
                                        {errors.subject && <span className="invalid-feedback">Temat jest wymagany</span>}
                                    </div>
                                </div>

                                <div className="third">
                                    <textarea
                                        {...register("message", {
                                            required: true,
                                        })}
                                        placeholder="Wiadomość *"
                                    ></textarea>
                                    {errors.message && <span className="invalid-feedback">Wiadomość jest wymagana</span>}
                                </div>

                                <div className="tm_button">
                                    <button type="submit" className="color">
                                        <span className="wrapper">
                                            <span className="first">Wyślij</span>
                                            <span className="second">Wyślij</span>
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
