export const getOffers = async () => {
    const enabledOffers = require("../../offers/offers.json");

    const parsedOffers: Array<{ title: string; body: string; parameters: Record<string, string>; path: string }> = [];
    for (const offerName of enabledOffers) {
        const offerPath = require("../../offers/" + offerName).default;
        const response = await fetch(offerPath);
        const explodedBody = (await response.text()).split("---");

        const parsedParameters: Record<string, string> = {};
        let title = "";
        if (explodedBody[0] === "" && (explodedBody[2] || "")[0] === "\n") {
            const parameters = explodedBody[1].split("\n");

            for (const parameter of parameters) {
                const [key, value] = parameter.split(": ");
                if (key && value) {
                    if (key === "title") {
                        title = value;
                    }
                    parsedParameters[key] = value;
                }
            }
            if (!title) {
                console.warn(`No title in ${offerName}`);
                continue;
            }
        }
        parsedOffers.push({
            title,
            body: explodedBody.splice(2).join("---"),
            parameters: parsedParameters,
            path: offerPath,
        });
    }

    return parsedOffers;
};
