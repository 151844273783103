import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const photos = [
    "Sielanka-wesele2.JPG",
    "Sielanka-wesele.JPG",
    "ELIKSIR-00242.JPG",
    "ELIKSIR-00252.JPG",
    "ELIKSIR-00507.JPG",
    "ELIKSIR-00308.JPG",
    "ELIKSIR-00371.JPG",
    "ELIKSIR-00484.JPG",
    "Sielanka_39.jpg",
    "Sielanka_60.jpg",
    "Sielanka_63.jpg",
    "Sielanka_161.jpg",
    "RYS_2294.jpg",
    "RYS_2565.jpg",
    "RYS_9039.jpg",
    "RYS_9370.jpg",
];
const Portfolio = () => {
    return (
        <SimpleReactLightbox>
            <div className="portfolio_list">
                <ul className="gallery_zoom">
                    {/* <ModalVideo channel="youtube" autoplay isOpen={isOpen} videoId="1gyTUHP6ne8" onClose={() => setOpen(false)} />
                    <li data-aos="fade-right" data-aos-duration="1200">
                        <div className="list_inner video">
                            <a href="https://www.youtube.com/watch?v=1gyTUHP6ne8" target="_blank" rel="noopener noreferrer" className="title">
                                <h3>Aura Dione </h3>
                                <span>Youtube Shoot</span>
                            </a>
                            <img src="img/portfolio/3.jpg" alt="Youtube Shoot" onClick={() => setOpen(true)} />
                        </div>
                    </li> */}

                    <SRLWrapper>
                        {photos.map((photo) => {
                            const loadedPhoto = require(`./photos/${photo}`).default;
                            return (
                                <li key={photo} data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                                    <div className="list_inner">
                                        {/* <a className="title" target="_blank" rel="noopener noreferrer">
                                            <h3>{photo.title}</h3>
                                            <span>{photo.description}</span>
                                        </a> */}

                                        <a href={loadedPhoto}>
                                            <img src={loadedPhoto} alt={""} />
                                        </a>
                                    </div>
                                </li>
                            );
                        })}
                    </SRLWrapper>
                </ul>
            </div>
        </SimpleReactLightbox>
    );
};

export default Portfolio;
