import { useEffect, useState } from "react";
import Modal from "react-modal";
import { getOffers } from "./api";
import ReactMarkdown from "react-markdown";
import "./style.scss";

Modal.setAppElement("#root");

const ServiceAnimation = () => {
    const [isModalOpen, setIsModalOpen] = useState("");
    const [offers, setOffers] = useState<Awaited<ReturnType<typeof getOffers>>>([]);

    useEffect(() => {
        getOffers().then((offers) => {
            setOffers(offers);
        });
    }, []);

    return (
        <div className="tm_services" id="service">
            <div className="container">
                <div className="tm_title_holder">
                    <span>Usługi</span>
                    <h2>Wybierz coś dla siebie</h2>
                    <p>W mojej ofercie znajdziecie cały wachlarz usług - w zależności od Waszych potrzeb i od tego na jakim etapie organizacji jesteście</p>
                </div>

                <div className="service_list">
                    <ul>
                        {offers.map((v, i) => (
                            <li key={v.title}>
                                <div className="list_inner" onClick={() => setIsModalOpen(v.path)} data-aos="fade-right" data-aos-duration="1200">
                                    <img className="svg" src={v.parameters.icon} alt="" />
                                    <div className="service_title">
                                        <h3>{v.title}</h3>
                                    </div>
                                    <span className="learn_more">
                                        Dowiedz się więcej<span></span>
                                    </span>
                                </div>

                                <Modal
                                    isOpen={isModalOpen === v.path}
                                    onRequestClose={() => setIsModalOpen("")}
                                    contentLabel="My dialog"
                                    className="custom-modal"
                                    overlayClassName="custom-overlay"
                                    closeTimeoutMS={500}
                                >
                                    <div className="tm_modalbox_service">
                                        <button className="close-modal" onClick={() => setIsModalOpen("")}>
                                            <img src="/img/svg/cancel.svg" alt="close icon" />
                                        </button>

                                        <div className="box_inner">
                                            <div className="description_wrap scrollable">
                                                <div className="popup_informations">
                                                    {v.parameters.image ? (
                                                        <div className="image">
                                                            <img src="img/thumbs/4-3.jpg" alt="" />
                                                            <div
                                                                className="main"
                                                                style={{
                                                                    backgroundImage: `url(${process.env.PUBLIC_URL + v.parameters.image})`,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <div className="description">
                                                        <ReactMarkdown children={v.body}></ReactMarkdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ServiceAnimation;
