import React from "react";

const Footer = () => {
    return (
        <>
            <div className="inner">
                <div className="copy">
                    <a
                        href="https://www.weselezklasa.pl/ogloszenia-weselne/wedding-fairy,53629/"
                        target="_blank"
                        title="Wedding Fairy - Wedding Plannerka"
                        rel="noreferrer"
                    >
                        <img
                            src="https://www.weselezklasa.pl/banery/Weselezklasa/button230x50bordowetlo.png"
                            alt="Wedding Fairy - Wedding Plannerka"
                            // border="0"
                        />
                    </a>
                </div>
            </div>
        </>
    );
};

export default Footer;
