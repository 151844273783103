import React from "react";
import Social from "../Social";

const SliderAnimation = () => {
    return (
        //    HERO
        <div className="tm_hero" id="home">
            <div className="background">
                <div
                    className="image"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + "img/slider/aga_transparent.png"})`,
                    }}
                ></div>
                <div className="overlay"></div>
            </div>
            {/* End bg */}

            <div className="go-to go-to-next">
                <a href="#service">
                    <span></span>
                </a>
            </div>
            {/* End animated goto button */}

            <div className="container">
                <div className="content">
                    <div className="content_inner">
                        <h3 className="name" data-aos="fade-up" data-aos-duration="1200">
                            Hej! Jestem Aga
                        </h3>
                        <h1 className="job" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
                            <p className="loop-text lead">Wedding Plannerka</p>
                            <span>Działająca na Pomorzu</span>
                        </h1>
                        <p className="text" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                            Skupiam się na potrzebach par oraz ich marzeniach o ślubie i weselu w ich niepowtarzalnym stylu!
                        </p>
                        <div className="mobile_social" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
                            <Social />
                        </div>
                        {/* End .social */}

                        <div className="tm_button" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                            <a className="anchor" href="#service">
                                <span className="wrapper">
                                    <span className="first">Zobacz ofertę</span>
                                    <span className="second">Zobacz ofertę</span>
                                </span>
                            </a>
                        </div>
                        {/* End tm_button */}
                    </div>
                </div>
            </div>
            {/* End .container */}

            <div className="social" data-aos="fade-left" data-aos-duration="1200">
                <Social />
            </div>
            {/* End .social */}
        </div>
        //HERO
    );
};

export default SliderAnimation;
